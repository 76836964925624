/* eslint-disable func-names */
import {
  Flexbox,
  Form, ImageThumb, LoadManager, Mask, PageHeader, Styles,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import yup from '@/lib/yupPt'
import partnerLicensings from '@/services/api/settings/partnerLicensings'
import upload from '@/services/api/upload'
import React, { useEffect, useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import TextareaAutosize from 'react-textarea-autosize'
import { toast } from 'react-toastify'
import MemberFormAdd from './MemberFormAdd'
import MemberRow from './MemberRow'

const schema = yup.object().shape({
  banners: yup.array().of(yup.string().url()).optional(),
  phoneNumber: yup.string().trim(),
  whatsappNumber: yup.string().trim(),
  info: yup.string().optional(),
  contactEmail: yup.string().email().optional(),
})

const fileTypes = ['JPG', 'JPEG', 'PNG']

function Info({ title }) {
  usePageTitle({ title })
  const [updating, setUpdating] = useState(false)
  const {
    loading, data, error, fetchData,
  } = useAxiosRequest(partnerLicensings.current)
  const [files, setFiles] = useState([])
  const [members, setMembers] = useState([])

  const initialData = data ? {
    banners: data?.banners ?? [],
    info: data?.info ?? '',
    contactEmail: data?.contactEmail ?? '',
    phoneNumber: data?.phoneNumber ?? '',
    whatsappNumber: data?.whatsappNumber ?? '',
  } : null

  useEffect(() => {
    setMembers(data?.members ?? [])
  }, [data?.members])

  async function onSubmit(body) {
    try {
      setUpdating(true)
      const attachments = []
      // eslint-disable-next-line no-restricted-syntax
      for (const file of files) {
        // eslint-disable-next-line no-await-in-loop
        const { data: { url } } = await upload.sendFile(file)
        attachments.push(url)
      }
      await partnerLicensings.updateCurrent({
        ...body,
        members,
        banners: [...(body.banners ?? []), ...attachments],
      })
      toast.success('Institucional atualizado')
      await fetchData()
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setUpdating(false)
    }
  }

  function onSelectFiles(params) {
    const added = Object.values(params).filter((file) => !files.find((f) => f.name === file.name))
    setFiles([...files, ...(added)])
  }

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Institucional"
        icon="fas fa-info"
        subtitle="Detalhes institucionais para os usuários"
      />
      <LoadManager loading={loading} error={error}>
        <Flexbox.Columns>
          <Flexbox.Column className="col-6 col-sm-12">
            {
              Boolean(initialData) && !loading && (
                <fieldset disabled={updating}>
                  <div className="divider" />
                  <Form.Group>
                    <Form.Label htmlFor="members">Membros</Form.Label>
                    <>
                      {
                        members.length
                          ? (
                            members.map((member) => (
                              <MemberRow
                                key={member}
                                member={member}
                                onRemoved={(name) => setMembers(members.filter((n) => n !== name))}
                              />
                            ))
                          )
                          : <small><cite>Nenhum membro adicionado</cite></small>
                      }
                      <MemberFormAdd
                        onAdded={({ name }) => {
                          const previous = members.find((i) => i === name)
                          if (previous) return toast.error('Membro já cadastrado')
                          return setMembers([...members, name])
                        }}
                      />
                    </>
                  </Form.Group>
                  <div className="divider" />
                  <Form.Formik
                    initialValues={initialData}
                    onSubmit={onSubmit}
                    validationSchema={schema}
                    validateOnMount
                  >
                    {
                      ({
                        errors, touched, isValid, setFieldValue,
                      }) => (

                        <Form.ValidationForm>
                          <Form.Group className={`${touched?.contactEmail && errors?.contactEmail ? 'has-error' : ''}`}>
                            <Form.Label htmlFor="contactEmail">E-mail de contato</Form.Label>
                            <Form.ValidationField
                              name="contactEmail"
                              id="contactEmail"
                              type="email"
                              placeholder="E-mail de contato"
                            />
                            {
                              touched?.contactEmail
                                && errors?.contactEmail
                                ? <Form.Hint className="text-error">{errors.contactEmail}</Form.Hint> : null
                            }
                            <Form.Hint className="text-gray">
                              Se definido, na tela de informações no aplicativo Petis aparecerá
                              um botão para enviar e-mail para este endereço.
                              <br /><b>Para desabilitar deixe o parâmetro vazio</b>
                            </Form.Hint>
                          </Form.Group>
                          <div className="divider" />
                          <Form.Group>
                            <Form.Label htmlFor="phoneNumber">Telefone</Form.Label>
                            <Form.ValidationField name="phoneNumber">
                              {
                                ({ field }) => (
                                  <Mask.Phone
                                    id="phoneNumber"
                                    placeholder="(00) 99999-9999"
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...field}
                                    onAccept={(v) => setFieldValue('phoneNumber', v)}
                                  />
                                )
                              }
                            </Form.ValidationField>
                          </Form.Group>
                          <Form.Group>
                            <Form.Label htmlFor="whatsappNumber">Whatsapp</Form.Label>
                            <Form.ValidationField name="whatsappNumber">
                              {
                                ({ field }) => (
                                  <Mask.Phone
                                    id="whatsappNumber"
                                    placeholder="(00) 99999-9999"
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...field}
                                    onAccept={(v) => setFieldValue('whatsappNumber', v)}
                                  />
                                )
                              }
                            </Form.ValidationField>
                          </Form.Group>
                          <div className="divider" />
                          <Form.Group className={`${touched?.info && errors?.info ? 'has-error' : ''}`}>
                            <Form.Label htmlFor="info">Intitucional</Form.Label>
                            <Form.ValidationField
                              name="info"
                              id="info"
                            >
                              {
                                ({ field }) => (
                                  <TextareaAutosize
                                    name="info"
                                    style={{ resize: 'none' }}
                                    className="form-input"
                                    rows={1}
                                    maxRows={3}
                                    placeholder="Escreva um texto descrevendo a instituição..."
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...field}
                                  />
                                )
                              }
                            </Form.ValidationField>
                            {
                              touched?.info
                                && errors?.info
                                ? <Form.Hint className="text-error">{errors.info}</Form.Hint> : null
                            }
                          </Form.Group>
                          <div className="divider" />
                          <Form.Group>
                            <Form.Label htmlFor="banners">Banners</Form.Label>
                            <Form.ValidationField
                              name="banners"
                              id="banners"
                            >
                              {
                                ({ field }) => (
                                  <Flexbox.Columns>
                                    {
                                      field.value.map((i) => (
                                        <Flexbox.Column key={i} className="col-auto p-relative">
                                          <a
                                            onClick={() => (
                                              setFieldValue(field.name,
                                                field.value.filter((url) => i !== url))
                                            )}
                                            className="btn btn-sm btn-error btn-action"
                                            style={{ position: 'absolute', right: 0, top: 0 }}
                                          >
                                            <i className="fas fa-trash" />
                                          </a>
                                          <ImageThumb src={i} width={100} height={100} />
                                        </Flexbox.Column>
                                      ))
                                    }
                                  </Flexbox.Columns>
                                )
                              }
                            </Form.ValidationField>
                            <FileUploader
                              handleChange={onSelectFiles}
                              hoverTitle="Solte aqui"
                              multiple
                              types={fileTypes}
                            >
                              {
                                files.length === 0 ? (
                                  <div className="c-hand bg-gray text-center text-dark" style={{ paddingTop: 30, paddingBottom: 30 }}>
                                    <div className="empty-icon">
                                      <i className="fas fa-paperclip fa-lg" />
                                    </div>
                                    <div className="empty-title h5">Selecione os anexos</div>
                                    <div className="empty-subtitle">Clique ou arraste os anexos para adicionar</div>
                                  </div>
                                ) : (
                                  <div className="c-hand bg-gray text-center" style={{ paddingTop: 30, paddingBottom: 30 }}>
                                    <div className="empty-title h5">Anexos adicionados: {files.length}</div>
                                    <div className="empty-subtitle">Clique ou arraste anexos para adicionar mais</div>
                                  </div>
                                )
                              }
                            </FileUploader>
                          </Form.Group>
                          <div className="divider" />

                          <div className="text-right">
                            <button type="submit" disabled={!isValid} className={`btn btn-primary ${updating ? 'loading' : ''}`}>
                              <i className="fas fa-save" />
                              &nbsp;Salvar
                            </button>
                          </div>
                        </Form.ValidationForm>
                      )
                    }
                  </Form.Formik>
                </fieldset>
              )
            }
          </Flexbox.Column>
        </Flexbox.Columns>
      </LoadManager>
    </Styles.Container>
  )
}

export default Info
